import React from 'react'
import parse from 'html-react-parser';
import './cta.scss'
import Button from '../Button';
import Div from '../Div';
import Spacing from '../Spacing';

export default function Cta({title, description, btnText, btnLink, bgSrc, variant}) {
  return (
    <Div className={`cs-cta cs-style1 cs-bg  p-0  cs-shape_wrap_1 cs-position_1 ${variant?variant:''}`} style={{backgroundImage: `url(${bgSrc})`}}>
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-cta_in">
        <h3 className="cs-cta_title cs-semi_bold ">{parse(title)}</h3>
        <p className="cs-cta_desc">{parse(description)}</p>
        {btnText && (
          <>
            <Spacing lg='10' md='10' />
            <Button 
              btnLink={btnLink}
              btnText={btnText}
            />
          </>
        )}
      </Div>
    </Div>
  )
}
