import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';
import Div from '../Div';
import Spacing from '../Spacing';
import Cta from '../Cta';
import MovingText from '../MovingText';
import PortfolioSlider2 from '../Slider/PortfolioSlider2';
import { pageTitle } from '../../helper';
import RegionList from '../SelectList/RegionList';
import ModalApp from '../LoadModal';
import { PostAuthRequest, getRecords } from '../../utills/common';

export default function RegionWiseList() {  
  const  { region_id } = useParams('region_id');    
  const  { region_name } = useParams('region_name');
  pageTitle(region_name);
  const [sliders, setSliders] = useState([]);


  useEffect(() => {
      try { 
          // async function fetchData() {
          //   console.log(" Region ", region_id);
          //   let slidersData =   await getRecords('slider/get-sliders');
          //   // let sliderData = await PostNoAuthRequest("get-slider-profiles");
          //   // console.log(" sliders ", slidersData);
          //   let items = [];
          //   if(slidersData.success){
          //     slidersData.data?.map((slider, i) => (
          //       items.push({imgUrl:slider.image_file})
          //       ));
          //   }
          //   setSliders(items);
          // }
          // fetchData();
      } catch (error) {
          // setLoading(false); // Stop loading in case of error
          console.error(error);
      }      
    }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      
      {/* Select List Page */}
      <RegionList  region_name={region_name} region_id ={region_id} />
      {/* Select List Page */}

      {/*Modal Load Page */}
      <ModalApp />
      {/* Modal Load Page */}
     
    </>
  );
}
